import global from 'global';
import { Language } from './_types/common';
import { PetitionType } from './_types/petition/interfaces';

// Generated Types from _configurations
import type * as ImagesYaml from './_configurations/images';
import type * as ConfigYaml from './_configurations/config';
//import type * as MarkdownYaml from './_configurations/markdown';
import type * as StringsYaml from './_configurations/strings';

export type ServerConfiguration = ConfigYaml.Server;
export type ClientConfiguration = ConfigYaml.Client;
export type StringsOption = StringsYaml.Default;

const isTestEnvironment = global?.process?.env?.NODE_ENV?.toLowerCase() === 'test';

// TODO: define more of the types used and make sure the interface(s) match up.
export interface LanguageEntry {
  code: Language;
  name: string;
}

interface IBaseRaw {
  env: {
    [key: string]: string;
  };
  images: ImagesYaml.Images;
  strings: StringsYaml.Strings;
  config: ConfigYaml.Config;
  data?: {
    [key: string]: any;
  };
}

interface IBaseData {
  petitionTypes?: PetitionType[];
}

// default initialized
const getBase = (): any => {
  const base = global.__BASE__ || { env: null, images: null, strings: null };
  base.env = base.env || {};
  base.images = base.images || {};
  base.strings = base.strings || {};
  base.config = base.config || {};
  return base;
};

const base: IBaseRaw = getBase();
const environment = base.env;
const images = base.images;
const strings = base.strings;
const client = base.config.client;

// URLS
const api: string = environment.API_URL || '/api';
const login: string = environment.LOGIN_URL || '/auth/login';
const logout: string = environment.LOGOUT_URL || '/auth/login';
const kibana: string = environment.KIBANA_URL || '';
const faqurl: string = environment.FAQ_URL || '';
const qaurl: string = environment.QA_URL || '';
const userguideurl: string = environment.USERGUIDE_URL || '';

// extracted configuration
const getRawData = (c: any): IBaseData => c.data || {};
const data = getRawData(base.config);

const getLanguages = (l: any): LanguageEntry[] => l;
const languages = getLanguages(base.config.languages);
const config = { petition: base.config.petition };

export { api, login, logout, images, environment, client, data, config, strings, languages };

export default {
  api,
  login,
  logout,
  kibana,
  images,
  environment,
  client,
  config,
  data,
  strings,
  faqurl,
  qaurl,
  userguideurl,
  languages,
};

if (!isTestEnvironment) {
  console.log({
    base: {
      api,
      login,
      logout,
      kibana,
      images,
      environment,
      client,
      config,
      data,
      strings,
      faqurl,
      qaurl,
      userguideurl,
      languages,
    },
  });
}
